import { PolicyType } from "@today/api/tracker"

export function getPolicyTypeName(policyType: PolicyType) {
  switch (policyType) {
    case "TODAY_MORNING":
      return "To-Day 익일"
    case "TODAY_EVENING":
      return "To-Day 당일"
    case "TODAY_OVERNIGHT":
      return "To-Day 새벽"
    case "SME":
      return "인천 SME"
    case "TOGETHER":
      return "B2B 합짐"
    case "FORWARDING":
      return "택배위탁"
    case "GOODS_TO_LUCK":
      return "굿트럭"
    case "HANJIN":
      return "한진택배"
    case "MNP":
      return "M&P 협력"
    case "YLP":
      return "YLP 협력"
    case "CJ":
      return "CJ대한통운"
  }
}
